<template>
  <div class="mt-1 rs-report__content" v-resize="onResize">
    <section :class="pdfType ? 'mb-6' : 'mb-8 mb-lg-12'">
      <header class="rs-report__main-title">
        <slot name="mainTitle"></slot>
      </header>

      <v-divider
        v-if="!isMobile"
        :class="[
          pdfType ? 'mb-4 mt-2' : 'mb-8 mt-4',
          pdfType === 'print' ? 'grey' : 'white',
        ]"
        >&nbsp;</v-divider
      >

      <div
        class="mb-8 text-center rs-report__compare-anim"
        :class="{ 'is-mobile': isMobile }"
      >
        <ReportCompareAnimation
          v-if="!isMobile"
          :prospectScore="score"
          :averageScore="averageScore"
          :pdfType="pdfType"
          :age="age"
          :gender="gender"
        />

        <ReportCompareAnimationMobile
          v-if="isMobile"
          :prospectScore="score"
          :averageScore="averageScore"
          :age="age"
          :gender="gender"
        />
      </div>
      <div class="rs-bold-highlight" :class="pdfType ? 'mb-6' : ''">
        <slot name="likelihood"></slot>
      </div>
    </section>

    <section class="" :class="pdfType ? 'mb-6' : 'mb-8 mb-lg-12'">
      <header class="rs-report__secondary-title">
        <slot name="factorsTitle"></slot>
      </header>

      <v-divider
        v-if="!isMobile"
        :class="[
          pdfType ? 'mb-4 mt-2' : 'mb-8 mt-4',
          pdfType === 'print' ? 'grey' : 'white',
        ]"
        >&nbsp;</v-divider
      >

      <v-row>
        <slot name="factorsBoxes"></slot>
      </v-row>
    </section>

    <section class="" :class="pdfType ? 'mb-6' : 'mb-8 mb-lg-12'">
      <header class="rs-report__secondary-title">
        <slot name="howMuchLoseTitle"></slot>
      </header>

      <v-divider
        v-if="!isMobile"
        class="mt-4"
        :class="[
          pdfType ? 'mb-4' : 'mb-8',
          pdfType === 'print' ? 'grey' : 'white',
        ]"
        >&nbsp;</v-divider
      >

      <v-row class="align-center rs-report__howMuchLose">
        <v-col
          class="rs-bold-highlight"
          :class="pdfType ? 'col-4' : 'col-12 col-md-4'"
        >
          <slot name="howMuchLoseText"></slot>
        </v-col>
        <v-col :class="pdfType ? 'col-8' : 'col-12 col-md-8'">
          <slot name="howMuchLoseGraphic"></slot>
        </v-col>
        <v-col v-if="!advisor.organizationId" class="col-12 mt-2 text-center">
          <div
            class="mx-auto"
            :class="prospectOrigin === 'import' || isEditing ? 'mb-8' : ''"
            style="max-width: 650px"
          >
            <slot name="yourChoiceText"></slot>
          </div>
          <v-btn
            v-if="!pdfType"
            dark
            x-large
            elevation="8"
            color="primary"
            class="rs-report__cta"
            target="_blank"
            @click="handleCta"
          >
            <slot name="ctaText"></slot
          ></v-btn>
        </v-col>
      </v-row>
    </section>
    <section
      v-if="advisor && !isEditing && !pdfType"
      class="
        d-flex
        flex-column flex-md-row flex-wrap
        align-center
        justify-center
      "
    >
      <div>{{ advisor.companyName }}</div>
      <v-icon
        v-if="advisor.companyName && advisorAddressExists"
        color="white"
        class="d-none d-md-inline"
        >mdi-circle-small</v-icon
      >
      <div v-if="advisorAddressExists">
        <address class="text-center">
          {{
            advisor.address.streetAddress1
              ? advisor.address.streetAddress1
              : null
          }}{{
            advisor.address.streetAddress2
              ? `, ${advisor.address.streetAddress2}`
              : null
          }}{{ advisor.address.city ? `, ${advisor.address.city}` : null
          }}{{ advisor.address.state ? `, ${advisor.address.state}` : null
          }}{{
            advisor.address.postalCode
              ? `, ${advisor.address.postalCode}`
              : null
          }}
        </address>
      </div>
      <v-icon
        v-if="
          (advisor.companyName || advisorAddressExists) && advisor.companyUrl
        "
        color="white"
        class="d-none d-md-inline"
        >mdi-circle-small</v-icon
      >
      <a :href="advisor.companyUrl" target="_blank" title="Advisor's Website">{{
        formatCompanyUrl(advisor.companyUrl)
      }}</a>
    </section>
    <section v-if="isEditing" class="text-center">
      ### ADVISOR COMPANY, ADDRESS, WEBSITE ###
    </section>

    <!-- <div v-if="pdfType" class="break-after">&nbsp;</div> -->
    <div v-if="pdfType" class="break-before">&nbsp;</div>

    <v-divider
      v-if="!pdfType"
      :class="[
        pdfType ? 'mb-6 mt-2' : 'mb-8 mt-4',
        pdfType === 'print' ? 'grey' : 'white',
      ]"
      >&nbsp;</v-divider
    >

    <section
      class="rs-report__extra-content"
      :class="pdfType ? 'mb-6' : 'mb-8 mb-lg-12'"
    >
      <slot name="extraContent"></slot>
    </section>
    <section class="rs-report__disclosure">
      <h3 class="mb-4">Disclosure</h3>
      <p v-if="!isEditing"><slot name="specificAdvisorDisclosure"></slot></p>
      <p v-if="isEditing">### ADVISOR SPECIFIC DISCLOSURE ###</p>
      <p>
        <slot name="genericAdvisorDisclosure"></slot>
      </p>
    </section>
  </div>
</template>

<script>
import { debounce as _debounce } from "lodash";
import ReportCompareAnimation from "@/components/ReportCompareAnimation.vue";
import ReportCompareAnimationMobile from "@/components/ReportCompareAnimationMobile.vue";

export default {
  name: "ReportTemplateContent",

  props: {
    isEditing: {
      type: Boolean,
      required: false,
      default: false,
    },
    advisor: {
      type: Object,
      required: false,
    },
    prospectId: {
      type: String,
    },
    prospectOrigin: {
      type: String,
    },
    score: {
      type: Number,
      required: false,
      default: 72,
    },
    averageScore: {
      type: Number,
      required: false,
      default: 59,
    },
    lifetimeBenefitCut: {
      type: String,
      required: false,
      default: "---",
    },
    lifetimeBenefitEstimate: {
      type: String,
      required: false,
      default: "---",
    },
    age: {
      type: Number,
    },
    gender: {
      type: String,
    },
    pdfType: {
      type: String,
      required: false,
      default: null,
    },
  },
  components: { ReportCompareAnimation, ReportCompareAnimationMobile },
  data() {
    return {
      isMobile: false,
    };
  },
  computed: {
    ctaUrl() {
      return process.env.VUE_APP_REPORT_CTA_URL;
    },
    advisorAddressExists() {
      // if any part of the address exists
      let exists;
      const advisorAddress = this.advisor.address;
      if (advisorAddress) {
        exists = Object.keys(advisorAddress).some(function (k) {
          return advisorAddress[k] !== null && advisorAddress[k] !== "";
        });
      }

      return exists;
    },
  },

  methods: {
    onResize: _debounce(async function () {
      if (window.innerWidth < 768) {
        this.isMobile = true;
      } else {
        this.isMobile = false;
      }
    }, 500),
    formatCompanyUrl(url) {
      if (!url) {
        return;
      }

      if (url.startsWith("https://www.")) {
        const https = "https://www.";
        return url.slice(https.length);
      }

      if (url.startsWith("https://")) {
        const https = "https://";
        return url.slice(https.length);
      }

      if (url.startsWith("http://www.")) {
        const http = "http://www.";
        return url.slice(http.length);
      }
      if (url.startsWith("http://")) {
        const http = "http://";
        return url.slice(http.length);
      }

      return url;
    },

    handleCta() {
      if (this.isEditing) {
        return;
      } else if (this.advisor?.customReportCtaUrl) {
        window.open(this.advisor.customReportCtaUrl, "_self", "rel=noreferrer");
      } else {
        this.$router.push("/contact-requested/" + this.prospectId);
      }
    },
  },
};
</script>

<style lang="scss">
@import "@/scss/report";

.rs-report {
  &__extra-content {
    p {
      page-break-inside: avoid;
    }
  }
}

.break-after {
  display: block;
  page-break-after: always;
  position: relative;
}

.break-before {
  display: block;
  page-break-before: always;
  position: relative;
}
</style>
