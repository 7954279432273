<template>
  <div>
    <bubble-menu
      :editor="editor"
      :tippy-options="{ duration: 100 }"
      v-if="editor"
      class="d-flex flex-column"
    >
      <div class="mb-1">
        <v-btn
          small
          elevation="4"
          :color="
            editor.isActive('bold') ? 'rgba(255, 0, 255)' : 'grey darken-4'
          "
          @click="editor.chain().focus().toggleBold().run()"
          :class="{ 'is-active': editor.isActive('bold') }"
          class="mr-1 font-weight-bold white--text"
        >
          <v-icon>mdi-format-bold</v-icon>
        </v-btn>
        <v-btn
          small
          elevation="4"
          :color="
            editor.isActive('italic') ? 'rgba(255, 0, 255)' : 'grey darken-4'
          "
          @click="editor.chain().focus().toggleItalic().run()"
          :class="{ 'is-active': editor.isActive('italic') }"
          class="mr-1 font-weight-bold white--text"
        >
          <v-icon>mdi-format-italic</v-icon>
        </v-btn>
        <v-btn
          small
          elevation="4"
          :color="
            editor.isActive('underline') ? 'rgba(255, 0, 255)' : 'grey darken-4'
          "
          @click="editor.chain().focus().toggleUnderline().run()"
          :class="{ 'is-active': editor.isActive('underline') }"
          class="mr-1 font-weight-bold white--text"
        >
          <v-icon>mdi-format-underline</v-icon>
        </v-btn>
        <v-btn
          small
          elevation="4"
          color="grey darken-4"
          @click="editor.chain().focus().undo().run()"
          :class="{ 'is-active': editor.isActive('undo') }"
          class="mr-1 font-weight-bold white--text"
        >
          <v-icon>mdi-arrow-u-left-top</v-icon>
        </v-btn>
        <v-btn
          small
          elevation="4"
          color="grey darken-4"
          @click="editor.chain().focus().redo().run()"
          :class="{ 'is-active': editor.isActive('redo') }"
          class="mr-1 font-weight-bold white--text"
        >
          <v-icon>mdi-arrow-u-right-top</v-icon>
        </v-btn>
      </div>

      <div class="mb-1">
        <v-btn
          small
          elevation="4"
          :color="
            editor.isActive('heading', { level: 1 })
              ? 'rgba(255, 0, 255)'
              : 'grey darken-4'
          "
          @click="editor.chain().focus().toggleHeading({ level: 1 }).run()"
          :class="{ 'is-active': editor.isActive('heading', { level: 1 }) }"
          class="mr-1 font-weight-bold white--text"
        >
          <v-icon>mdi-format-header-1</v-icon>
        </v-btn>
        <v-btn
          small
          elevation="4"
          :color="
            editor.isActive('heading', { level: 2 })
              ? 'rgba(255, 0, 255)'
              : 'grey darken-4'
          "
          @click="editor.chain().focus().toggleHeading({ level: 2 }).run()"
          :class="{ 'is-active': editor.isActive('heading', { level: 2 }) }"
          class="mr-1 font-weight-bold white--text"
        >
          <v-icon>mdi-format-header-2</v-icon>
        </v-btn>
        <v-btn
          small
          elevation="4"
          :color="
            editor.isActive('heading', { level: 3 })
              ? 'rgba(255, 0, 255)'
              : 'grey darken-4'
          "
          @click="editor.chain().focus().toggleHeading({ level: 3 }).run()"
          :class="{ 'is-active': editor.isActive('heading', { level: 3 }) }"
          class="mr-1 font-weight-bold white--text"
        >
          <v-icon>mdi-format-header-3</v-icon>
        </v-btn>
        <v-btn
          small
          elevation="4"
          :color="
            editor.isActive({ textAlign: 'left' })
              ? 'rgba(255, 0, 255)'
              : 'grey darken-4'
          "
          @click="editor.chain().focus().setTextAlign('left').run()"
          :class="{ 'is-active': editor.isActive({ textAlign: 'left' }) }"
          class="mr-1 font-weight-bold white--text"
        >
          <v-icon>mdi-format-align-left</v-icon>
        </v-btn>
        <v-btn
          small
          elevation="4"
          :color="
            editor.isActive({ textAlign: 'center' })
              ? 'rgba(255, 0, 255)'
              : 'grey darken-4'
          "
          @click="editor.chain().focus().setTextAlign('center').run()"
          :class="{ 'is-active': editor.isActive({ textAlign: 'center' }) }"
          class="mr-1 font-weight-bold white--text"
        >
          <v-icon>mdi-format-align-center</v-icon>
        </v-btn>
        <v-btn
          small
          elevation="4"
          :color="
            editor.isActive({ textAlign: 'right' })
              ? 'rgba(255, 0, 255)'
              : 'grey darken-4'
          "
          @click="editor.chain().focus().setTextAlign('right').run()"
          :class="{ 'is-active': editor.isActive({ textAlign: 'right' }) }"
          class="mr-1 font-weight-bold white--text"
        >
          <v-icon>mdi-format-align-right</v-icon>
        </v-btn>
      </div>
      <!-- <div>
        <v-btn
          small
          elevation="4"
          :color="
            editor.isActive({ textStyle: { color: '#B9F18D' } })
              ? 'rgba(255, 0, 255)'
              : 'grey darken-4'
          "
          @click="editor.chain().focus().setColor('#ff8800').run()"
          :class="{
            'is-active': editor.isActive('textStyle', { color: '#ff8800' }),
          }"
          class="mr-1 font-weight-bold white--text"
          title="Set Orange Color"
        >
          <v-icon color="#ff8800">mdi-format-color-text</v-icon>
        </v-btn>
        <v-btn
          small
          elevation="4"
          color="grey darken-4"
          @click="editor.chain().focus().unsetColor().run()"
          class="mr-1 font-weight-bold white--text"
          title="Clear Color"
        >
          <v-icon color="#ddd">mdi-format-color-text</v-icon>
        </v-btn>
      </div> -->
    </bubble-menu>
    <editor-content :editor="editor" />
  </div>
</template>
<script>
import { BubbleMenu, Editor, EditorContent } from "@tiptap/vue-2";
// import Mention from "@tiptap/extension-mention";
import { Color } from "@tiptap/extension-color";
import Bold from "@tiptap/extension-bold";
import Document from "@tiptap/extension-document";
// import History from "@tiptap/extension-history";
import Italic from "@tiptap/extension-italic";
import Paragraph from "@tiptap/extension-paragraph";
import Placeholder from "@tiptap/extension-placeholder";
import Text from "@tiptap/extension-text";
import TextAlign from "@tiptap/extension-text-align";
import TextStyle from "@tiptap/extension-text-style";
import Underline from "@tiptap/extension-underline";
import CustomHeading from "@/components/editors/editorCustomHeadingExtension";
// import StarterKit from "@tiptap/starter-kit";

// import reportVariableSuggestions from "@/components/editors/editorHelpers/reportVariableSuggestions";

export default {
  components: {
    BubbleMenu,
    EditorContent,
  },
  props: {
    value: {
      type: String,
      default: "",
    },
    bg: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      editor: null,
    };
  },

  watch: {
    value(value) {
      // HTML
      const isSame = this.editor.getHTML() === value;

      // JSON
      // const isSame = JSON.stringify(this.editor.getJSON()) === JSON.stringify(value)

      if (isSame) {
        return;
      }

      this.editor.commands.setContent(value, false);
    },
  },

  mounted() {
    this.editor = new Editor({
      editorProps: {
        attributes: {
          class: this.bg === "light" ? "rs-editor--light" : "",
        },
      },
      content: this.value,
      extensions: [
        // StarterKit,
        Bold,
        Color,
        CustomHeading,
        Document,
        History,
        Italic,
        Paragraph,
        Placeholder.configure({
          // Use a placeholder:
          placeholder: "Write something…",
          // Use different placeholders depending on the node type:
          // placeholder: ({ node }) => {
          //   if (node.type.name === 'heading') {
          //     return 'What’s the title?'
          //   }

          //   return 'Can you add some further context?'
          // },
        }),
        Text,
        TextAlign.configure({
          types: ["heading", "paragraph"],
        }),
        TextStyle,
        Underline,
        // Heading.configure({
        //   levels: [1, 2],
        //   HTMLAttributes: {
        //     class: "text-h6 text-md-h5 text-lg-h4 text-center font-weight-bold",
        //   },
        // }),
        // Mention.configure({
        //   HTMLAttributes: {
        //     class: "mention",
        //   },
        //   reportVariableSuggestions,
        // }),
      ],
      onUpdate: () => {
        // HTML
        this.$emit("input", this.editor.getHTML());

        // JSON
        // this.$emit("input", this.editor.getJSON());
      },
    });
  },

  beforeDestroy() {
    this.editor.destroy();
  },
};
</script>
<style lang="scss">
.ProseMirror p.is-editor-empty:first-child::before {
  content: attr(data-placeholder);
  float: left;
  color: #e6e6e79d;
  pointer-events: none;
  height: 0;
  padding-left: 0.5rem;
}
</style>
