<template>
  <div class="rs-report-editor">
    <v-banner
      app
      sticky
      elevation="8"
      color="white"
      style="z-index: 6"
      class="mx-n8 mt-n4 px-4"
    >
      <v-row>
        <v-col class="d-flex align-center col-12 col-md">
          <h1 class="text-h6">
            Score Report Template Editor
            <small class="red--text">(Deprecated - use Report Templates)</small>
          </h1>
        </v-col>
        <v-spacer class="d-none d-md-block"></v-spacer>
        <v-col v-if="selectedTemplate" class="col-12 col-md d-flex justify-end">
          <v-btn
            :disabled="loading"
            @click="variablesDialog = true"
            color="primary"
            class="mr-4"
          >
            Variables List
          </v-btn>
          <v-btn
            :loading="loading"
            :disabled="loading"
            @click="handleSave"
            color="primary"
          >
            Save
          </v-btn>
        </v-col>
      </v-row></v-banner
    >

    <div v-if="selectedTemplate" class="mt-6 mb-4 d-flex align-center">
      <div class="mr-8">
        Editing template: <strong>{{ selectedTemplate.name }}</strong>
      </div>
      <div>
        <v-tabs v-model="currentGeneration">
          <v-tab v-for="g in generations" :key="g">
            {{ g }}
          </v-tab>
        </v-tabs>
      </div>
    </div>
    <v-row v-if="!selectedTemplate" class="mt-8 align-center">
      <v-col>
        <v-card
          max-width="700"
          outlined
          elevation="12"
          class="mx-auto"
          style="position: relative"
        >
          <v-card-title class="justify-center flex-column">
            <div class="error--text text-caption">
              (Deprecated - use Report Templates)
            </div>

            Select an Existing Report Template
          </v-card-title>
          <v-card-subtitle class="text-center"
            >Select an existing report template to edit.
          </v-card-subtitle>
          <v-card-text class="text-center">
            <div class="d-flex align-center justify-center">
              <v-progress-circular
                v-if="!reportTemplates"
                indeterminate
                size="48"
                color="primary"
              ></v-progress-circular>
            </div>

            <v-select
              v-if="reportTemplates"
              v-model="userSelectTemplate"
              hide-details
              :items="reportTemplates"
              item-text="name"
              item-value="id"
              label="Select Template"
              outlined
              return-object
            >
              <template v-slot:selection="{ item }">
                <div>
                  <div>{{ item.name }}</div>

                  <small class="text-grey">Created: {{ item.createdAt }}</small>
                </div>
              </template>
            </v-select>
            <v-btn
              :disabled="!userSelectTemplate"
              color="primary"
              class="my-6"
              @click="handleSelectTemplate"
            >
              Open Template
            </v-btn>
            <!-- <v-divider />
            <v-card-title class="justify-center">
              Create a New Report Template
            </v-card-title>
            <v-card-subtitle class="text-center"
              >Name and build a new report template.
            </v-card-subtitle>
            <v-text-field
              label="Template Name"
              persistent-hint
              hint="Required: Provide a short descriptive name."
              outlined
              v-model="newTemplateName"
            ></v-text-field>
            <v-btn @click="handleCreate" color="primary" class="mt-4">
              Create Template
            </v-btn> -->
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <div class="rs-report rs-report-edit">
      <div v-if="selectedTemplate" class="mt-5 rs-report-wrap needs-bg">
        <v-container>
          <ReportTemplateHeader :isEditing="true"></ReportTemplateHeader>
          <v-tabs-items v-model="currentGeneration">
            <v-tab-item
              v-for="t in selectedTemplate.template"
              :key="t.generation"
            >
              <!-- <component
                :is="reportTemplateContentComponent"
                :isEditing="true"
                :score="72"
                :averageScore="34"
              /> -->

              <ReportTemplateContent
                :isEditing="true"
                :score="72"
                :averageScore="34"
              >
                <template #mainTitle
                  ><EditorSimpleText v-model="t.content.mainTitle"
                /></template>

                <template #likelihood
                  ><EditorSimpleText v-model="t.content.likelihood"
                /></template>
                <template #factorsTitle>
                  <EditorSimpleText v-model="t.content.factorsTitle" />
                </template>
                <template #factorsBoxes>
                  <v-col
                    v-for="(box, index) in t.content.factorsBoxes"
                    :key="box.title + index"
                    class="col-12 col-lg-4 d-flex"
                    :class="index !== 2 ? 'col-sm-6' : ''"
                  >
                    <v-card
                      elevation="8"
                      class="d-flex flex-column text-center flex-grow-1"
                    >
                      <v-card-title
                        class="justify-center font-weight-bold text-h6"
                      >
                        <EditorSimpleText v-model="box.title" bg="light" />
                      </v-card-title>
                      <div class="px-4">
                        <EditorSimpleText v-model="box.text" bg="light" />
                      </div>
                    </v-card>
                  </v-col>
                </template>
                <template #howMuchLoseTitle
                  ><EditorSimpleText v-model="t.content.howMuchLoseTitle"
                /></template>
                <template #howMuchLoseText
                  ><EditorSimpleText v-model="t.content.howMuchLoseText"
                /></template>
                <template #howMuchLoseGraphic>
                  <v-hover v-slot="{ hover }">
                    <div
                      class="rs-report__howMuchLoseGraphic"
                      :style="loading ? 'min-height: 350px' : null"
                    >
                      <ReportHowMuchLoseAnimation
                        v-if="!loading"
                        lossValue="$123,456"
                        lifetimeValue="$1,123,456"
                        :lossTextContent="
                          t.content.howMuchLoseGraphic.lossText
                            ? t.content.howMuchLoseGraphic.lossText
                            : 'Write Something...'
                        "
                        :benefitTextContent="
                          t.content.howMuchLoseGraphic.benefitText
                            ? t.content.howMuchLoseGraphic.benefitText
                            : 'Write Something...'
                        "
                      />
                      <v-btn
                        v-if="hover"
                        @click="handleShowGraphicEditorDialog(t)"
                        elevation="10"
                        color="rgba(255, 0, 255)"
                        class="white--text"
                        >Edit Graphic</v-btn
                      >
                    </div>
                  </v-hover>
                </template>
                <template #yourChoiceText
                  ><EditorSimpleText v-model="t.content.yourChoiceText"
                /></template>
                <template #ctaText
                  ><EditorSimpleText v-model="t.content.ctaText"
                /></template>
                <template #extraContent
                  ><EditorSimpleText v-model="t.content.extraContent"
                /></template>
                <template #genericAdvisorDisclosure
                  ><EditorSimpleText
                    v-model="t.content.genericAdvisorDisclosure"
                /></template>
              </ReportTemplateContent>
            </v-tab-item>
          </v-tabs-items>
        </v-container>
      </div>
    </div>
    <v-dialog
      v-if="variablesDialog"
      v-model="variablesDialog"
      max-width="800px"
    >
      <v-card tile>
        <v-card-title class="mb-6 text-h6"
          >Report Example Variables</v-card-title
        >
        <v-card-text>
          <p>
            Use these variables surrounded by double curly braces:
            <code v-pre>{{ variable_name }}</code> which will be replaced by the
            dynamic value when the report is generated.
          </p>
          <v-simple-table>
            <template v-slot:default>
              <thead>
                <tr>
                  <th class="text-left">Variable</th>
                  <th class="text-left">Example</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="item in reportMetaVars" :key="item.variable">
                  <td>
                    <v-tooltip top>
                      <template v-slot:activator="{ on, attrs }">
                        <code
                          v-bind="attrs"
                          v-on="on"
                          @click="handleMetaCopy(formatMetaVar(item.variable))"
                          >{{ formatMetaVar(item.variable) }}</code
                        >
                      </template>
                      <span>{{ metaVarTooltip }}</span>
                    </v-tooltip>
                  </td>
                  <td>{{ item.example }}</td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
        </v-card-text>

        <v-card-actions class="justify-end">
          <v-btn text @click="variablesDialog = false">Close</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog
      v-if="hasGraphicEditorData"
      v-model="showGraphicEditorDialog"
      width="500px"
      max-width="800px"
    >
      <v-card tile>
        <v-card-title class="mb-6 text-h6">Edit Graphics Content</v-card-title>
        <v-card-text>
          <v-text-field
            v-model="currentGenerationGraphicData.lossText"
            label="How it works loss text"
            outlined
            hint="Use a small amount of text - about 5-6 words"
            persistent-hint
            class="mb-4"
          ></v-text-field>

          <v-text-field
            v-model="currentGenerationGraphicData.benefitText"
            label="How it works benefit text"
            outlined
            hint="Use a small amount of text - about 5-6 words"
            persistent-hint
          ></v-text-field>
        </v-card-text>

        <v-card-actions class="justify-end">
          <v-btn
            text
            @click="handleCloseGraphicEditorDialog"
            color="error"
            class="mr-4"
            >Cancel</v-btn
          >
          <v-btn
            :loading="loading"
            :disabled="loading"
            @click="handleSave"
            color="primary"
          >
            Save
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-snackbar
      v-model="showSnackBar"
      :timeout="2000"
      :color="snackBarColor"
      :elevation="24"
      transition="slide-x-reverse-transition"
      bottom
      right
      tile
    >
      <div class="text-center">{{ snackBarText }}</div>
    </v-snackbar>
  </div>
</template>

<script>
// import merge from "lodash/merge";
import { API, graphqlOperation } from "aws-amplify";
import {
  createReportTemplate,
  updateReportTemplate,
} from "@/graphql/mutations";
import { listReportTemplates } from "@/graphql/queries";
import linkBuilderMixin from "@/mixins/linkBuilderMixin";

import ReportHowMuchLoseAnimation from "@/components/ReportHowMuchLoseAnimation.vue";
import ReportTemplateHeader from "@/components/ReportTemplateHeader";
import ReportTemplateContent from "@/components/ReportTemplateContent";
import EditorSimpleText from "@/components/editors/EditorSimpleText";

// const initialTemplate = function () {
//   return [
//     {
//       generation: "Generation X",
//       content: {
//         mainTitle: "Your Social Security Risk Score",
//         likelihood: "",
//         factorsTitle: "Your Social Security Risk Score Factors",
//         factorsBoxes: [
//           {
//             title: "Finances",
//             text: "<p> Your estimated retirement income will be higher than <strong>92%</strong> of Americans.</p><p>Your benefits may be reduced through means-testing.</p>",
//           },
//           {
//             title: "Voting Power",
//             text: "<p> You are a member of <strong>Generation X</strong>, and your voting power is declining. </p><p> By <strong>2034</strong>, <strong>78%</strong> of voters will be younger than you, and may not support higher taxes to fund your full benefits. </p>",
//           },
//           {
//             title: "Life Expectancy",
//             text: "<p> You are likely to be alive in <strong>2034</strong> with an average life expectancy of <strong>89.4</strong> years. </p><p> In <strong>1983</strong>, your Promised Benefits were reduced. Benefits were also reduced in <strong>1977</strong>, for everyone <strong>60</strong> and younger. </p>",
//           },
//         ],
//         howMuchLoseTitle: "How Much Could I Lose?",
//         howMuchLoseText: "",
//         howMuchLoseGraphic: {
//           lossText: "",
//           benefitText: "",
//         },
//         yourChoiceText:
//           "You have a choice: leave the success of your retirement in the hands of the government or take action to protect your benefits.",
//         extraContent: "",
//         genericAdvisorDisclosure: "",
//       },
//     },
//     {
//       generation: "Baby Boomer",
//       content: {
//         mainTitle: "Your Social Security Risk Score",
//         likelihood: "",
//         factorsTitle: "Your Social Security Risk Score Factors",
//         factorsBoxes: [
//           {
//             title: "Finances",
//             text: "<p> Your estimated retirement income will be higher than <strong>92%</strong> of Americans.</p><p>Your benefits may be reduced through means-testing.</p>",
//           },
//           {
//             title: "Voting Power",
//             text: "<p> You are a member of <strong>Generation X</strong>, and your voting power is declining. </p><p> By <strong>2034</strong>, <strong>78%</strong> of voters will be younger than you, and may not support higher taxes to fund your full benefits. </p>",
//           },
//           {
//             title: "Life Expectancy",
//             text: "<p> You are likely to be alive in <strong>2034</strong> with an average life expectancy of <strong>89.4</strong> years. </p><p> In <strong>1983</strong>, your Promised Benefits were reduced. Benefits were also reduced in <strong>1977</strong>, for everyone <strong>60</strong> and younger. </p>",
//           },
//         ],
//         howMuchLoseTitle: "How Much Could I Lose?",
//         howMuchLoseText: "",
//         howMuchLoseGraphic: {
//           lossText: "",
//           benefitText: "",
//         },
//         yourChoiceText:
//           "You have a choice: leave the success of your retirement in the hands of the government or take action to protect your benefits.",
//         extraContent: "",
//         genericAdvisorDisclosure: "",
//       },
//     },
//     {
//       generation: "Millennials",
//       content: {
//         mainTitle: "Your Social Security Risk Score",
//         likelihood: "",
//         factorsTitle: "Your Social Security Risk Score Factors",
//         factorsBoxes: [
//           {
//             title: "Finances",
//             text: "<p> Your estimated retirement income will be higher than <strong>92%</strong> of Americans.</p><p>Your benefits may be reduced through means-testing.</p>",
//           },
//           {
//             title: "Voting Power",
//             text: "<p> You are a member of <strong>Generation X</strong>, and your voting power is declining. </p><p> By <strong>2034</strong>, <strong>78%</strong> of voters will be younger than you, and may not support higher taxes to fund your full benefits. </p>",
//           },
//           {
//             title: "Life Expectancy",
//             text: "<p> You are likely to be alive in <strong>2034</strong> with an average life expectancy of <strong>89.4</strong> years. </p><p> In <strong>1983</strong>, your Promised Benefits were reduced. Benefits were also reduced in <strong>1977</strong>, for everyone <strong>60</strong> and younger. </p>",
//           },
//         ],
//         howMuchLoseTitle: "How Much Could I Lose?",
//         howMuchLoseText: "",
//         howMuchLoseGraphic: {
//           lossText: "",
//           benefitText: "",
//         },
//         yourChoiceText:
//           "You have a choice: leave the success of your retirement in the hands of the government or take action to protect your benefits.",
//         extraContent: "",
//         genericAdvisorDisclosure: "",
//       },
//     },
//     {
//       generation: "Silent Generation",
//       content: {
//         mainTitle: "Your Social Security Risk Score",
//         likelihood: "",
//         factorsTitle: "Your Social Security Risk Score Factors",
//         factorsBoxes: [
//           {
//             title: "Finances",
//             text: "<p> Your estimated retirement income will be higher than <strong>92%</strong> of Americans.</p><p>Your benefits may be reduced through means-testing.</p>",
//           },
//           {
//             title: "Voting Power",
//             text: "<p> You are a member of <strong>Generation X</strong>, and your voting power is declining. </p><p> By <strong>2034</strong>, <strong>78%</strong> of voters will be younger than you, and may not support higher taxes to fund your full benefits. </p>",
//           },
//           {
//             title: "Life Expectancy",
//             text: "<p> You are likely to be alive in <strong>2034</strong> with an average life expectancy of <strong>89.4</strong> years. </p><p> In <strong>1983</strong>, your Promised Benefits were reduced. Benefits were also reduced in <strong>1977</strong>, for everyone <strong>60</strong> and younger. </p>",
//           },
//         ],
//         howMuchLoseTitle: "How Much Could I Lose?",
//         howMuchLoseText: "",
//         howMuchLoseGraphic: {
//           lossText: "",
//           benefitText: "",
//         },
//         yourChoiceText:
//           "You have a choice: leave the success of your retirement in the hands of the government or take action to protect your benefits.",
//         extraContent: "",
//         genericAdvisorDisclosure: "",
//       },
//     },
//   ];
// };
export default {
  components: {
    ReportHowMuchLoseAnimation,
    ReportTemplateHeader,
    ReportTemplateContent,
    EditorSimpleText,
  },
  mixins: [linkBuilderMixin],

  data() {
    return {
      loading: false,
      reportMetaVars: null,
      metaVarTooltip: "Click to copy",
      currentGeneration: 0,
      generations: [
        "Generation X",
        "Baby Boomer",
        "Millennials",
        "Silent Generation",
      ],
      userSelectTemplate: null,
      selectedTemplate: null,
      newTemplateName: null,
      reportTemplates: null,
      variablesDialog: false,
      showGraphicEditorDialog: false,
      hasGraphicEditorData: false,
      showSnackBar: false,
      snackBarColor: "info",
      snackBarText: "",
      cacheTest: null,
    };
  },
  computed: {
    currentGenerationGraphicData() {
      if (this.selectedTemplate && this.currentGeneration !== null) {
        return this.selectedTemplate.template[this.currentGeneration].content
          .howMuchLoseGraphic;
      } else {
        return null;
      }
    },

    // reportTemplateContentComponent() {
    //   this.reportTemplateIndex;
    //   return () =>
    //     import(
    //       `@/components/reportTemplates/${
    //         this.reportTemplates[this.reportTemplateIndex].componentName
    //       }.vue`
    //     );
    // },
  },
  mounted() {
    this.init();
  },
  methods: {
    async init() {
      // get variables list
      const reportMeta = await this.getReportMeta();
      const newArray = [];

      for (const [key, value] of Object.entries(reportMeta.variables)) {
        const newObj = {};

        newObj.variable = key;
        newObj.example = value;

        newArray.push(newObj);
      }

      this.reportMetaVars = newArray;
      // console.log("this.reportmeta  ", reportMeta);
      this.getReportTemplates();
    },
    formatMetaVar(variable) {
      return `{{${variable}}}`;
    },
    async handleMetaCopy(variable) {
      const copied = await this.genericCopy(variable);

      if (copied) {
        this.metaVarTooltip = "Copied to clipboard!";
      }

      setTimeout(() => {
        this.metaVarTooltip = "Click to copy";
      }, 500);
    },
    async getReportMeta() {
      try {
        const response = await fetch(
          process.env.VUE_APP_SCORE_API + "score_report_meta_v3",
          {
            method: "POST",
            headers: {
              // Authorization: `Bearer ${process.env.NEEDTOKEN}`,
              "Content-Type": "application/json",
            },
            // body: JSON.stringify(data),
          }
        );

        if (response.ok) {
          const results = await response.json();
          if (results.success) {
            return results;
          }
        } else {
          throw new Error("Something failed fetching meta.");
        }
      } catch (error) {
        console.log("Score fetch error: ", error);
      }
    },
    async getReportTemplates() {
      // Get all report templates to set in select
      try {
        const response = await API.graphql(
          graphqlOperation(listReportTemplates)
        );
        const result = response.data.listReportTemplates;
        console.log("RESULT OF TEMPLTES", result);
        if (!result.items || result.items.length === 0) {
          throw new Error("No report templates could be found.");
        } else {
          this.reportTemplates = result.items.filter((t) => {
            console.log("TTT", t);
            return !t.version || t.version === 1;
          });
        }
      } catch (error) {
        this.showSnackBar = true;
        (this.snackBarText = "Error retrieving report templates: "), error;
        this.snackBarColor = "error";
      }
    },
    async handleSelectTemplate() {
      const selectedTemplate = Object.assign({}, this.userSelectTemplate);
      // console.log("this.selectedTemplate", selectedTemplate);
      selectedTemplate.template = JSON.parse(selectedTemplate.template);

      this.selectedTemplate = selectedTemplate;

      selectedTemplate.template.forEach((gen) => {
        if (!gen.content.howMuchLoseGraphic) {
          gen.content.howMuchLoseGraphic = {
            lossText: "",
            benefitText: "",
          };
        }
      });
    },
    async handleCreate() {
      this.loading = true;

      // const template = new initialTemplate();
      // const newTemplate = JSON.stringify(template);

      try {
        const response = await API.graphql(
          graphqlOperation(createReportTemplate, {
            input: {
              template: JSON.stringify([
                {
                  generation: "",
                  content: [],
                },
              ]),
              current: false,
              version: null,
              name: this.newTemplateName,
            },
          })
        );
        const result = response.data.createReportTemplate;

        console.log("handleCreate RESULT", result);
      } catch (error) {
        console.log("error", error);
      } finally {
        this.loading = false;
      }
    },
    handleShowGraphicEditorDialog(t) {
      this.cacheTest = Object.assign({}, t.content.howMuchLoseGraphic);
      this.showGraphicEditorDialog = true;
      this.hasGraphicEditorData = true;
    },
    handleCloseGraphicEditorDialog() {
      // reset forms if dialog is canceled with cached data that is set when dialog is opened
      console.log("handleCloseGraphicEditorDialog, cache", this.cacheTest);
      this.selectedTemplate.template[
        this.currentGeneration
      ].content.howMuchLoseGraphic = Object.assign({}, this.cacheTest);
      this.showGraphicEditorDialog = false;

      this.$nextTick(() => {
        this.hasGraphicEditorData = false;
      });
    },
    async handleSave() {
      this.loading = true;

      let updateData = Object.assign({}, this.selectedTemplate);
      updateData.template = JSON.stringify(updateData.template);

      try {
        const response = await API.graphql(
          graphqlOperation(updateReportTemplate, {
            input: updateData,
          })
        );
        const result = response.data.updateReportTemplate;
        result.template = JSON.parse(result.template);
        console.log("Saved Result", result);
        // this.selectedTemplate = Object.assign({}, result);
        // this.mergeDeep(this.selectedTemplate, result);

        this.showSnackBar = true;
        this.snackBarText = "Template saved!";
        this.snackBarColor = "success";
      } catch (error) {
        console.log("Error updating report template: ", error);
        this.showSnackBar = true;
        this.snackBarText = "Error saving template";
        this.snackBarColor = "error";
      } finally {
        this.loading = false;
        this.showGraphicEditorDialog = false;

        this.$nextTick(() => {
          this.hasGraphicEditorData = false;
        });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/scss/report";
.theme--light.v-tabs-items {
  background-color: transparent;
}

.rs-report-edit {
  .v-tabs-items {
    padding: 0.5rem;
  }
  .rs-editor {
    &--light {
      &.ProseMirror {
        &-focused {
          box-shadow: 0 3px 40px rgba(magenta, 0.3);
        }
      }
    }
  }
  ::v-deep .ProseMirror {
    outline: 2px dashed cyan;
    background-color: rgba(cyan, 0.067);
    transition: all 0.15s linear;

    &-focused {
      background-color: rgba(magenta, 0.08);
      outline: 2px dashed magenta;
      box-shadow: 0 3px 40px rgba(magenta, 0.593);
    }
  }
}
</style>
